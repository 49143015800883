/*
Template Name: Trrader -  Admin & Dashboard Template
Author: Themesdesign
Version: 1.1.0
Contact: themesdesign.in@gmail.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/forms";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/calendar";
@import "custom/plugins/color-picker";
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/datatable";
@import "custom/plugins/datepicker";
@import "custom/plugins/flot";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/google-map";
@import "custom/plugins/parsley";
@import "custom/plugins/range-slider";
@import "custom/plugins/rating";
@import "custom/plugins/responsive-table";
@import "custom/plugins/select2";
@import "custom/plugins/session-timeout";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/switch";
@import "custom/plugins/table-editable";
@import "custom/plugins/vector-maps";
@import "custom/plugins/x-editable";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import url(https://fonts.googleapis.com/css?family=Roboto:500);

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;
.create-resume-button-div{
  align-items: center;
  display: flex;
  justify-content: center;
  .create-resume-button{
    padding: 20px;
    max-width: 320px;
  }
  
}


.google-btn {
  width: 184px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 500px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  cursor: pointer;
  display: flex;
  .google-icon-wrapper {
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 500px;
    background-color: $white;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .google-icon {
    width: 18px;
    height: 18px;
  }
  .btn-text {
    justify-content: center;
    display: flex;
    align-items: center;
    float: right;
    margin-left: 8px !important;
    // margin: 11px 11px 0 0;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
  
}

.calculated-risk-card{
  box-shadow: 0 0px 1px #565656d9;
  .card-header{
    min-height: 54px;
    line-height: 30px;
  }
  .card-body{
    // text-align: center;
    min-height: 85px;
  }
  .card-text{
    font-size: 1.3em;
    font-weight: bold;
  }
}
.chart-bottom-card-danger{
  background: #fc4b4b;
  padding-top: 3px;
  font-weight: bold;
  color: white;
  .chart-bottom-card-danger-text{
    color: white;
  }
}

// @import url(https://fonts.googleapis.com/css?family=Roboto:500);

.grouped-row{
  border: 1px solid #ddd;
  padding: 1em;
  margin-left: 0px;
  border-radius: 5px;
}

.result-container{
  box-shadow: #b3b3b8c7 -3px -3px 5px;
  padding: 0px;
  width: 99%;
  margin-left: 12px;
  border-radius: 5px;
  .alert-heading{
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.calculator-form-input-label{
  font-weight: normal;
  color: black;
}

.calculator-form-input{
  min-width: 50%;
  // max-width: 160px;
  padding:0.7rem 0.75rem;
  color: black;
  font-weight: bold;
}

.option-premium-candle-container{
  position: relative;
  height: 200px;
  width: 100%;
  border-bottom: 1px solid #eee;
  // background: #eee;
  text-align: center;
  .candle-left-align{
    position: absolute;
    left: 40%;
    bottom: 0;
    // margin-bottom: 100px;
    width: 40px;
    transform: translate(-50%);
    border-top: 1px dashed black;
    .span-number{
      position: absolute;
      margin-top: -10px;
      margin-left: -55px;

    }
    .span-label{
      position: absolute;
      margin-top: -20px;
      margin-left: -10px;
    }
    
  }
  .candle-right-align{
    position: absolute;
    left: 60%;
    bottom: 0;
    transform: translate(-50%);
    border-top: 1px dashed black;
    width: 40px;
    .span-number{
      position: absolute;
      margin-top: -10px;
      margin-left: 25px;  
    }
    .span-label{
      position: absolute;
      margin-top: -20px;
      margin-left: 0px;
    }
   
  }
  .candle-top-align{
    position: absolute;
    left: 60%;
    bottom: 0;
    width: 40px;
    transform: translate(-50%);
    border-top: 1px dashed black;
    .span-number{
      position: absolute;
      margin-top: -10px;
      margin-left: 25px;  
    }
    .span-label{
      position: absolute;
      margin-top: -20px;
      margin-left: 0px;
    }
    
  }
  .candle-center-align{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
  }
  .option-premium-body{
    height: 0;
    position: absolute;
    width: 30px;
    background: $metrics-card-primary-color;
    z-index: 1;
    &:hover{
      background: $metrics-card-primary2-color;
      cursor: pointer;
    }
  }
  .option-premium-sl{
    z-index: 2;
    width: 30px;
    position: absolute;
    background: #cb1b00;
    opacity: 0.7;
    transition: all 0.2s ease-in-out;
    &:hover{
      cursor: pointer;
      opacity: 1;
    }
  }
  .option-premium-target{
    z-index: 3;
    position: absolute;
    width: 30px;
    background: $metrics-card-success-color;
    &:hover{
      background: rgb(1, 154, 121);
      cursor: pointer;
    }
  }
}
.option-premium-label-container{
  // display: flex;
  // justify-content: center;
  // align-items: flex-end;
  .premium-label{
    display: flex;
    align-items: center;
    height: 20px;
    color: black;
    .premium-square{
      height: 10px;
      width: 10px;
      &.premium{
        background: $metrics-card-primary-color;
      }
      &.sl{
        // background: $metrics-card-danger-color;
        background: #cb1b00;
        opacity: 0.8;
      }
      &.target{
        background: $metrics-card-success-color;
      }
    }
  }
}
.disable-the-inputs{
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}
.enable-the-inputs{
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
}
.underline-click{
  text-decoration: underline;
  color: #4285f4;
  cursor: pointer;
}
.show-click-link{
  display: block;
}
.hide-click-link{
  display: none;
}

.sub-max-sl-text{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    margin-bottom: 0;
    width: 200px;
}

.configuration-header{
  background:$metrics-card-primary2-color;
  .card-title{
    // height: 50px;
    // line-height: 50px;
  }
}

.metrics-card{
  // max-width: 380px;
  min-height: 160px;
  color: white;
  .card-body{
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
    // justify-content: center;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    color:white;
    .card-info-header{
      font-weight: 500;
    }
    .metric-number{
      font-size: 2.5em;
      font-weight: bold;
      .sub-metric-number{
        font-size: 0.5em;
        font-weight: normal;
      }
    }
  }
  h6{
    color:white;
  }
}

.metrics-card-primary{
  background: $metrics-card-primary-color;
  color: $white;
}

.metrics-card-primary-2{
  background: $metrics-card-primary2-color;
  color: $white;
  &.btn:hover{
    background: $metrics-card-primary-color;
    color: $white;
  }
}



.metrics-card-text-color{
  color: $metrics-card-primary-color;
}


.metrics-card-danger{
  background: $metrics-card-danger-color;
  color: $white;
}

.metrics-card-text-color-danger{
  color: $metrics-card-danger-color !important;
}

.metrics-card-success{
  background: $metrics-card-success-color;
  color: $white;
}

.metrics-card-text-color-success{
  color: $metrics-card-success-color;
}

.metrics-card-warning{
  background: $metrics-card-warning-color;
  color: $white;
}

.metrics-card-text-color-warning{
  color: $metrics-card-warning-color;
}

.metrics-card-primary2{
  background: $metrics-card-primary2-color;
  color: $white;
}

.metrics-card-text-color-primary2{
  color: $metrics-card-primary2-color;
}

.metrics-card-raw-info{
  background: $metrics-card-raw-info-color;
  color: $metrics-card-primary2-color;
  
  .card-body{
    .card-info-header{
      font-weight: 500;
    }
    color: $metrics-card-primary2-color;
    .metric-number{
      color: $metrics-card-primary2-color;
    }
  }
}

.metrics-card-white-info{
  background: $white;
  border:1px solid #eee;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  .card-body{
    color: $metrics-card-primary2-color;
    .card-info-header{
      font-weight: 500;
      color: $metrics-card-primary2-color;
    }
    // color: $metrics-card-primary2-color;
    // .metric-number{
    //   color: $metrics-card-primary2-color;
    // }
  }
  &.option-premium-stacked-container{
    min-height: 362px;
  }
}

.primary-button{
  background: $metrics-card-primary2-color;
  color: $white;
  border: 0;
  outline: 0;
  padding: 0.6em 1.25em;
  &:hover{
    background:$metrics-card-primary-color;
  }
}
.landing-header-main{
  min-height: 75vh;
  margin-bottom: 20px;
}
.strikediag {
  background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
  color: red;
}
.offer-image{
  position: absolute;
  width: 70px;
  border-radius: 500px;
  top: -100px;
  left: -0px;
}

.extra-card-header{
  height: 60px;
  font-size: 1.6em;
  font-weight: normal;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.row-hover{
  position: relative;
 
  .row-button{
    display: none;
    right: 0;
    border:0;
    position: absolute;
    background: $metrics-card-primary2-color;
  }
  &:hover{
    background: #eee;
    cursor: pointer;
    .row-button{
      display: block;
    }
  }
}

.nav-link{
  @media (max-width: 480px) {
    min-height: 60px;
  }
  &.active{
    background: $metrics-card-primary2-color !important;
    color: $white;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

  }
}

.calculator-form{
  .cta-button{
    font-size: 1.1em;
    border-radius: 4px;
    min-width: 120px;
    &.reset-button{
      background: #fff;
      color: $metrics-card-primary2-color !important;
      border-color: $metrics-card-primary2-color !important;
      &:hover{
        background: $metrics-card-primary-color !important;
        border-color: $metrics-card-primary-color !important;
        color: #fff !important;
      }
    }
  }
}

.free-plan-sidebar-button{
  margin-top: 50px;
  border: 1px solid rgb(173, 173, 173);
  border-left: 0 !important;
  padding: 12px 20px !important;
  text-align: center;
  font-size: 1.2em;
}


.full-page-loading{
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.full-page-loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20,.7), rgba(0, 0, 0, .7));
  background: -webkit-radial-gradient(rgba(20, 20, 20,.7), rgba(0, 0, 0,.7));
}

/* :not(:required) hides these rules from IE9 and below */
.full-page-loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.full-page-loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.accordion-button:not(.collapsed) {
  color: #575bde;
  background-color: #575bde2e;
}
.accordion-body{
  color: black;
  .text-muted{
    color: black !important;
  }
}